<template>
  <el-pagination
    ref="pagination"
    class="pageView"
    @next-click="nextClick"
    @size-change="sizeChange"
    @prev-click="prevClick"
    @current-change="currentChange"
    :current-page.sync="currentPage"
    :page-sizes="pageSizes"
    :page-size="pageSize"
    layout="prev, pager, next, sizes, total, jumper"
    :total="total"
    :prev-text="prevText"
    :next-text="nextText"
    background
  >
  </el-pagination>
</template>
<script>
export default {
  name: "pageBean",
  data() {
    return {
      currentPage: 1,
      pageSizes: [10, 20, 50, 100],
      pageSize: 10,
    };
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    prevText: {
      type: String,
      default: "上一页",
    },
    nextText: {
      type: String,
      default: "下一页",
    },
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    
  },
  methods: {
    currentChange(currentPage) {
      this.$emit("clickPage", {
        pageSize: this.pageSize,
        currentPage: currentPage,
      });
    },
    prevClick(currentPage) {
      this.$emit("clickPage", {
        pageSize: this.pageSize,
        currentPage: currentPage,
      });
    },
    nextClick(currentPage) {
      this.$emit("clickPage", {
        pageSize: this.pageSize,
        currentPage: currentPage,
      });
    },
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.currentPage = 1;
      this.$emit("clickPage", {
        pageSize: pageSize,
        currentPage: 1,
      });
    },
  },
};
</script>
<style lang="scss">
.pageView {
  padding: 30px 0 10px 0;
  text-align: center;
  &.el-pagination .btn-next,
  &.el-pagination .btn-prev {
    font-size: 14px;
    padding: 0 8px;
    background-color: #eaf0fc;
    color: #333333;
    border-radius: 4px;
    min-width: 70px;
    height: 30px;
    span {
      font-size: 14px;
    }
  }
  &.el-pagination .el-pager li {
    color: #333333;
    border-radius: 4px;
    font-weight: normal;
    background: #eaf0fc;
  }
}
.el-pagination.is-background .btn-prev:disabled,
.el-pagination.is-background .btn-next:disabled {
  color: #aaaaaa;
  background-color: #eeeeee;
  // display: none;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #115FEF;
}
</style>
  
  
  
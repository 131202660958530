<template>
  <div class="yj-frame-main">
    <div class="up-banner">
      <h1>下载中心</h1>
      <img src="../../assets/image/fwzc/1.png" alt="">
    </div>
    <div class="qyhxys ydrj">
      <h1>移动应用软件</h1>
      <div class="rjlr">
        <img src="../../assets/image/fwzc/2.png" alt="">
      </div>
    </div>
    <div class="FFFback">
      <div class="qyhxys jkwd">
        <h1>开放接口文档</h1>
        <div>
          <el-row :gutter="100" v-if="wdList.length>0">
            <el-col :span="12" v-for="(item,index) in wdList" :key="index">
              <div class="grid-content bg-purple">
                <img src="../../assets/image/fwzc/pdf.png">
                <div @click="downLoad(item)">{{item.documentName}}</div>
              </div>
            </el-col>
          </el-row>
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
        <div class="pagination">
          <page-bean
            @clickPage="clickPage"
            :total="totlePage"
          ></page-bean>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pageBean from "@/components/pageBean.vue";
import { DocumentList } from "@/api/api";
export default {
  name: "download",
  components: {
    pageBean,
  },
  data(){
    return {
      wdList:[],
      totlePage: 0,
      queryParams: {
        currentPage: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(){
      let params = this.queryParams
      DocumentList(params).then((res) => {
        if (res.success == true) {
          this.wdList = res.data.records;
          this.totlePage = res.data.total;
        }
      });
    },
    clickPage(obj) {
      this.queryParams.pageSize = obj.pageSize;
      this.queryParams.currentPage = obj.currentPage;
      this.getList();
    },
    downLoad(data){
      var a = document.createElement('a');
      a.href = "//" + data.documentAddress.substring(data.documentAddress.indexOf("://") + 3);
      a.click();
      var body = document.querySelector('body');
      body.appendChild(a);
      body.removeChild(a);
    },
  },
}
</script>
<style lang="scss" scoped>
.ydrj{
  width: 75rem;
  margin: 50px auto;
  height: auto;
  padding-top: 0;
  .rjlr{
    img{
      width: 100%;
    }
  }
}
.jkwd{
  height: auto;
  padding-top: 3.5rem;
  // margin-bottom: 4rem;
  padding-bottom: 4rem;
  .bg-purple{
    display: flex;
    align-items: center;
    background: #f8f8f8;
    padding: 20px 40px;
    margin-bottom: 40px;
    img{
      width: 50px;
    }
    div{
      margin-left: 30px;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
.pagination{
  margin: 0;
}
</style>
